import React from 'react';
import './LoadingSpinner.css'; 
import { Circles, RotatingLines } from 'react-loader-spinner'; 

const LoadingSpinner = () => {
  return (
    <div className='loading-sp'> 
        {/* <Circles
            height="70"
            width="70"
            color="#2563EB"
            ariaLabel="Loading" 
        /> */}

        <RotatingLines 
          visible={true}
          height="80"
          width="80"
          color="grey"
          strokeWidth="5"
          animationDuration="0.75"
          ariaLabel="rotating-lines-loading"
        />
    </div>
  )
}

export default LoadingSpinner;