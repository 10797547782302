import React from 'react';
import './CountdownPop.css';

const CountdownPop = () => {
  return (
    <>
    <article className='CountdownPop'>
      <div className="wheel"></div>
    </article>
    </>
  )
}

export default CountdownPop;  
