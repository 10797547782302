import React, { useEffect, useState } from 'react'; 
import './Investor.css'; 
import Select, {components} from 'react-select';
import { searchBtn, searchIcon, userPic } from '../../assets';
import API from '../../api/Api';
import LoadingSpinner from '../../components/LoadingSpinner';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';  
import moment from 'moment';

const Investor = () => {
  const [loading, setLoading] = useState(false);
  const [listInvestor, setListInvestor] = useState([]);

  const [period, setPeriod] = useState(null);
  const [periodId, setPeriodId] = useState(null);
  const [searchCountry, setSearchCountry] = useState(null);
  const [searchCountryId, setSearchCountryId] = useState(null);
  const [searchState, setSearchState] = useState(null);
  const [searchStateId, setSearchStateId] = useState(null);


  const [yearDefault, setYearDefault] = useState(''); 
  const yearOptions = [
      { value: '2023', label: '2023' },
      { value: '2024', label: '2024' },
      { value: '2025', label: '2025' },
      { value: '2026', label: '2026' },
      { value: '2027', label: '2027' }, 
      { value: '2028', label: '2028' },
      { value: '2029', label: '2029' },
      { value: '2030', label: '2030' },
      { value: '2031', label: '2031' },
      { value: '2032', label: '2032' },
      { value: '2033', label: '2033' },
      { value: '2034', label: '2034' },
  ];

  const [monthDefault, setMonthDefault] = useState('');  
  const monthOptions = [
      { value: '1', label: 'January' },
      { value: '2', label: 'February' },
      { value: '3', label: 'March' },
      { value: '4', label: 'April' },
      { value: '5', label: 'May' }, 
      { value: '6', label: 'June' },
      { value: '7', label: 'July' },
      { value: '8', label: 'August' },
      { value: '9', label: 'September' },
      { value: '10', label: 'October' },
      { value: '11', label: 'November' },
      { value: '12', label: 'December' },
  ];

  const CaretDownIcon = () => {
    // return <i class="fa-solid fa-arrow-up-wide-short" style={{ position: 'absolute', left: 15, bottom: 9 }}></i>;
    return <i class="material-icons" style={{ position: 'absolute', left: 12, bottom: 6 }}>sort</i>; 
  };
  
  const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        <CaretDownIcon />
      </components.DropdownIndicator>
    );
  };

  const listInvestorMethod = () => {
    setLoading(true);
    API.get("/map/report/users/top-investors", {
      params: {
        search: periodId,
        year: yearDefault.value,
        month: monthDefault.value, 
        country: searchCountryId,
        state: searchStateId, 
      }
    }).then((res) => { 
      setListInvestor(res.data.data); 
    }).catch((err) => {
      toast.error(err.response.data.message, {position:"top-center"});
    }).finally(() => {
      setLoading(false); 
    });  
  }

  const onSearchClicked = () => {
    if(period === '') { 
      setPeriodId(null); 
    }else {
      setPeriodId(period); 
    }

    if(searchCountry === '') { 
      setSearchCountryId(null); 
    }else {
      setSearchCountryId(searchCountry); 
    }

    if(searchState === '') { 
      setSearchStateId(null);
    }else {
      setSearchStateId(searchState);  
    }
  }

  const onResetSearch = () => {
    setPeriodId(null); 
    setSearchCountryId(null);
    setSearchStateId(null);  
    setYearDefault('');
    setMonthDefault(''); 
  }


  useEffect(() => {
    listInvestorMethod();   
  }, [yearDefault, monthDefault, periodId, searchCountryId, searchStateId]); 

  return (
    <section className='achiever-section'>
        <article className='table-article'>
            <div className="table-search">
              <div className="search-select">
              <Select
                options={yearOptions}
                defaultValue={yearDefault}
                placeholder="Years"
                isSearchable={false}
                onChange={setYearDefault}
                components={{ DropdownIndicator }}
                styles={{
                    control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor: state.isFocused ? 'grey' : 'black',
                        borderRadius: '5px',
                        textAlign: 'center',
                        cursor: "pointer",
                        backgroundColor: state.isFocused ? "#055189" : "white",
                    }),
                    placeholder: (baseStyles, state) => ({
                        ...baseStyles,
                        color: state.isFocused ? "white" : "black",
                    }),
                    option: (base) => ({
                        ...base,
                        border: '1px solid black',
                        height: '100%',
                        textAlign: 'center',
                    }),
                    dropdownIndicator: (baseStyles, state) => ({
                        ...baseStyles,
                        color: state.isFocused ? "white" : "black",
                    }), 
                    menuList: (base) => ({
                        ...base,
                        "::-webkit-scrollbar": {
                          width: "0px",
                          height: "0px",
                        },
                    }),
                    singleValue: (styles, state) => {
                        return {
                          ...styles,
                          color: state.isSelected ? "white" : "black",
                        };
                    },
                    indicatorSeparator: () => ({ display: "none" }),
                }}
                />
              </div>

              <div className="search-select">
              <Select
                options={monthOptions}
                defaultValue={monthDefault}
                placeholder="Months"
                isSearchable={false}
                onChange={setMonthDefault}
                components={{ DropdownIndicator }}
                styles={{
                    control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor: state.isFocused ? 'grey' : 'black',
                        borderRadius: '5px',
                        textAlign: 'center',
                        cursor: "pointer",
                        backgroundColor: state.isFocused ? "#055189" : "white",
                    }),
                    placeholder: (baseStyles, state) => ({
                        ...baseStyles,
                        color: state.isFocused ? "white" : "black",
                    }),
                    option: (base) => ({
                        ...base,
                        border: '1px solid black',
                        height: '100%',
                        textAlign: 'center',
                    }),
                    dropdownIndicator: (baseStyles, state) => ({
                        ...baseStyles,
                        color: state.isFocused ? "white" : "black",
                    }), 
                    menuList: (base) => ({
                        ...base,
                        "::-webkit-scrollbar": {
                          width: "0px",
                          height: "0px",
                        },
                    }),
                    singleValue: (styles, state) => {
                        return {
                          ...styles,
                          color: state.isSelected ? "white" : "black",
                        };
                    },
                    indicatorSeparator: () => ({ display: "none" }),
                }}
                />
              </div>

              <div className="search-field achieve-field">
                <input 
                  type="search" 
                  placeholder='Search Country'
                  onChange={(e)=> {setSearchCountry(e.target.value)}} 
                  onKeyDown={onSearchClicked} 
                /> 

                <img src={searchIcon} alt="icon" onClick={() => {onSearchClicked()}} />
              </div>

              <div className="search-field achieve-field achv-serh2">
                <input 
                  type="search" 
                  placeholder='Search State'
                  onChange={(e)=> {setSearchState(e.target.value)}} 
                  onKeyDown={onSearchClicked} 
                />

                <img src={searchIcon} alt="icon" onClick={() => {onSearchClicked()}} />
              </div>

              <div className="search-field achieve-field achv-serh2">
                <input 
                  type="search" 
                  placeholder='Search Member ID'
                  onChange={(e)=> {setPeriod(e.target.value)}} 
                  onKeyDown={onSearchClicked} 
                />
                <img src={searchIcon} alt="icon" onClick={() => {onSearchClicked()}} /> 
              </div>

              <img src={searchBtn} alt="icon" className='ach-search-btn' onClick={() => {onResetSearch()}} />
            </div>

            <div className="table-wrapper">
            <table className='fl-table'>
              <thead>
              <tr>
                  <th>Sl. No.</th>
                  <th>Image</th>
                  <th>Country</th>
                  <th>State</th>
                  <th>Member ID</th>
                  <th>Member Name</th>
                  <th className='last-th'>Total Invest</th>
                  <th>Invest Date & Time</th>
                  <th>Total Re-Invest</th>
                  <th>Re-Invest Date & Time</th>
              </tr>
              </thead>

              <tbody>
              {loading && <LoadingSpinner />}
              {listInvestor.map((data, index) => {
              return(
              <tr key={index}>
                  <td>{index+1}</td>
                  <td><img src={data.kyc.userSelfie} alt="user pic" className='td-img'/></td>
                  <td>{data.country}</td>
                  <td>{data.kyc.state ? data.kyc.state : '--'}</td>
                  <td>{data.referralCode}</td>
                  <td>{data.name}</td>
                  <td>{parseFloat(data.investedBalance).toFixed(2)}</td>
                  <td>{moment(data.investDate).format('DD-MM-YYYY HH:mm:ss')}</td>
                  <td>{parseFloat(data.reinvestedIncome).toFixed(2)}</td>
                  <td>{moment(data.reinvestDate).format('DD-MM-YYYY HH:mm:ss')}</td>
              </tr>
              )})}

              {/* <tr>
                  <td>2</td>
                  <td>1</td>
                  <td><img src={userPic} alt="icon" className='td-img'/></td>
                  <td>India</td>
                  <td>Maharashtra</td>
                  <td>VT-123456</td>
                  <td>Sam</td>
                  <td>56465.00</td>
              </tr> */}

              </tbody> 
              </table>
            </div>
        </article>
    </section>
  )
}

export default Investor; 