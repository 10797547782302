import React from 'react';
import './UserInfoPop.css'; 
import { coinIcon, bcPop, crossBox1, coinIcon2 } from '../assets';
import moment from 'moment';

const UserInfoPop = ({setShowUserInfoPop, singleUser}) => {
    console.log(singleUser); 
  return (
    <article className='UserInfoPop'>
        <div className="topsvg">
            <svg viewBox="0 0 500 200">
                <path d="M 0 50 C 150 150 300 0 500 80 L 500 0 L 0 0" fill="rgba(255, 203, 17, 0.51)"></path>
            </svg>
        </div>
     
        <h5 className='top-title'>User Information</h5> 
        <img src={singleUser.kyc.userSelfie} alt="logo" className='bc-logo-pop' onClick={()=> setShowUserInfoPop(false)}/>

        <div className="info-details">
            <div className="detail memlvl-div">
                <h6 className='mem-p'>Members: <span>{singleUser.teamCount}</span></h6>
                <h6 className='lvl-p'>Level: <span>{singleUser.level}</span></h6>
            </div>


            <div className="detail">
                <h6>Name:</h6>
                <p>{singleUser.name}</p>
            </div>

            <div className="detail">
                <h6>Joining Date:</h6>
                <p>{moment(singleUser.createdAt).format('DD-MM-YYYY')}</p>
            </div>

            <div className="detail">
                <h6>Member ID:</h6>
                <p>{singleUser.referralCode}</p>
            </div>

            <div className="detail">
                <h6>Stock Balance:</h6>
                <p>{parseFloat(singleUser.stockBalance).toFixed(2)} <img src={coinIcon} alt="logo" className='coin-icon'/></p> 
            </div>

            <div className="detail">
                <h6>Invest Balance:</h6>
                <p>{parseFloat(singleUser.investedBalance).toFixed(2)} <img src={coinIcon} alt="logo" className='coin-icon'/></p> 
            </div>

            <div className="detail">
                <h6>Total Income:</h6>
                <p>{parseFloat(singleUser.totalIncome).toFixed(2)} <img src={coinIcon} alt="logo" className='coin-icon'/></p> 
            </div>

            <div className="detail">
                <h6>Total Income:</h6>
                <p>{parseFloat(singleUser.totalIncomeSvtcn).toFixed(2)} <img src={coinIcon2} alt="logo" className='coin-icon'/></p> 
            </div>

            <div className="detail">
                <h6>No. of Buys:</h6>
                <p>{singleUser.buyCount}</p> 
            </div>

            <div className="detail">
                <h6>No. of Sells:</h6>
                <p>{singleUser.sellCount}</p> 
            </div>

            <div className="detail">
                <h6>No. of Exchange:</h6>
                <p>{singleUser.exchangeCount}</p>  
            </div>
        </div>

        <div className="bottomsvg">
            <svg width="100%" height="100%" id="svg" viewBox="0 0 1440 390" xmlns="http://www.w3.org/2000/svg" class="transition duration-300 ease-in-out delay-150"><path d="M 0,400 L 0,150 C 81.85714285714286,180.96428571428572 163.71428571428572,211.92857142857142 299,215 C 434.2857142857143,218.07142857142858 623.0000000000001,193.25000000000003 771,161 C 918.9999999999999,128.74999999999997 1026.2857142857142,89.07142857142858 1131,86 C 1235.7142857142858,82.92857142857142 1337.857142857143,116.46428571428571 1440,150 L 1440,400 L 0,400 Z" stroke="none" stroke-width="0" fill="#8ed1fc" fill-opacity="1" class="transition-all duration-300 ease-in-out delay-150 path-0"></path></svg>
        </div>

        <img src={crossBox1} alt="cross" className='cross-icon' onClick={()=> setShowUserInfoPop(false)}/>
    </article>
  )
}

export default UserInfoPop; 