import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import {
  createBrowserRouter,
  Outlet,
  RouterProvider,
  //ScrollRestoration
} from 'react-router-dom';
import Header from './components/Header';
import Achiever from './pages/Achiever/Achiever';
import Investor from './pages/Investor/Investor';
import Login from './pages/Login/Login';
import { tokenLoder } from './utils/Auth'; 
import { logoutAction } from './utils/Logout';


const Layout = () => {
  return (
    <div>
      <Header passedComponent={<Outlet />} />
    </div>
  );
}

const router = createBrowserRouter([
  {
    path: "/",
    children: [
      {
        path: "/",
        element: <Login />
      },
    ]
  },
  {
    path: "/map",
    element: <Layout />,
    id: "root", 
    loader: tokenLoder,  
    children: [
      {
        path: "achievers",
        element: <Achiever />
      },
      {
        path: "investors",
        element: <Investor />
      },
      {
        path: "logout",
        action: logoutAction,
      }
    ]
  }
]);


function App() {
  return (
    <div className="App">
      {<RouterProvider router={router} />}
    </div>
  );
}

export default App;
