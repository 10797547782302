import React from 'react';
import { redirect } from 'react-router-dom';


export function logoutAction() {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('loggedIn');
    localStorage.removeItem('expiration'); 
    localStorage.removeItem('reloadCount'); 
    return redirect('/'); 
}
