import React, { useState, useEffect } from 'react';
import './Header.css'; 
import { locationIcon, locIcon, locationIcon2, menuIcon, menuIcon2, searchIcon } from '../assets';
import Select from 'react-select';
import { GoogleMap, useLoadScript, MarkerF, Marker } from '@react-google-maps/api';
import UserInfoPop from './UserInfoPop';
import CountdownPop from './CountdownPop';
import {Form, useLoaderData, useNavigate, useSubmit, redirect} from "react-router-dom"; 
import { getTokenDuration } from '../utils/Auth';
import API from '../../src/api/Api'; 
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';  
import MapTable from './MapTable';

const Header = (props) => {
    const navigate = useNavigate(); 
    const token = useLoaderData();
    const submit = useSubmit();

    useEffect(() => {
        if(!token) { 
          localStorage.removeItem('loggedIn');
          return; 
        }
    
        if (token === 'EXPIRED') {
          localStorage.removeItem('loggedIn');
          submit(null, {action: '/logout', method: 'post'}); 
          return; 
        }
    
        const tokenDuration = getTokenDuration(); 

        setTimeout(() => {
          localStorage.removeItem('loggedIn');
          submit(null, {action: '/logout', method: 'post'});  
        }, tokenDuration);     // 1*60*60*1000 
           
    }, [token, submit]); 


    const reloadCount = localStorage.getItem('reloadCount');
    useEffect(() => {
      if(reloadCount < 2) {
        localStorage.setItem('reloadCount', 2); 
        window.location.reload();
      } else {
        localStorage.removeItem('reloadCount'); 
      }
    }, []);

    const [showCountdownPop, setShowCountdownPop] = useState(true); 
    useEffect(() => {
        const timer = setTimeout(() => {
          setShowCountdownPop(false);
        }, 5000);
    
        return () => clearTimeout(timer);
    }, []);


    // API //////////////////////////////////////////
    const [loading, setLoading] = useState(false);
    const [listCountry, setListCountry] = useState([]);
    const [listStates, setListStates] = useState([]);

    const [listLocation, setListLocation] = useState([]);
    const [singleUser, setSingleUser] = useState([]);
    const [userSelfie, setUserSelfie] = useState(null); 
    const [imgHight, setImgHight] = useState(40);
    const [imgWidth, setImgWidth] = useState(60);
    const [userSelfie2, setUserSelfie2] = useState(locIcon); 

    const [listMapUser, setListMapUser] = useState([]);
    const [showMapTable, setShowMapTable] = useState(false);

    const [period, setPeriod] = useState(null);
    const [periodId, setPeriodId] = useState(null);

    const [countryDefault, setCountryDefault] = useState({
        value: '', 
        label: 'Select Country'
    }); 

    const [stateDefault, setStateDefault] = useState(''); 
  
    const listCountryMethod = () => {
      setLoading(true);
      API.get("/map/country/list").then((res) => { 
        const dataArr = res.data.data; 
        const newArr = dataArr.map(item => ({
            value: item.country,
            label: item.country
        }));

        setListCountry(newArr); 
      }).catch((err) => {
        // toast.error(err.response.data.message, {position:"top-center"});
      }).finally(() => {
        setLoading(false); 
      });  
    }

    const listStatesMethod = () => {
        setLoading(true);
        API.get(`/map/country/state/list?country=${countryDefault.value}`).then((res) => { 
          const dataArr = res.data.data; 
          const newArr = dataArr.map(item => ({
              value: item,
              label: item
          }));
  
        setListStates(newArr); 
        }).catch((err) => {
          // toast.error(err.response.data.message, {position:"top-center"});
        }).finally(() => {
          setLoading(false); 
        });  
    }

    const listLocationMethod = () => {
        setLoading(true);
        API.get("/map/country/location", {
            params: {
                search: periodId,
            }
        }).then((res) => { 
          const dataArr = res.data.data; 
          setListLocation(dataArr); 
          toast.success(res.data.message, {position:"top-center"});
        }).catch((err) => {
          toast.error(err.response.data.message, {position:"top-center"});
        }).finally(() => {
          setLoading(false); 
        });  
    }

    const listSingleUserMethod = () => {
        setLoading(true);
        API.get("/map/user/list", {
            params: {
              search: periodId,
            }
        }).then((res) => { 
          setSingleUser(res.data.data[0]); 
          setUserSelfie(res.data.data[0].kyc.userSelfie); 
        }).catch((err) => {
          // toast.error(err.response.data.message, {position:"top-center"});
        }).finally(() => {
          setLoading(false); 
        });  
    }

    const listMapUserMethod = () => {
      setLoading(true);
      API.get("/map/user/list", {
          params: {
            country: countryDefault.value,
            state: stateDefault.value, 
          }
      }).then((res) => { 
        setListMapUser(res.data.data);  
      }).catch((err) => {
        // toast.error(err.response.data.message, {position:"top-center"});
      }).finally(() => {
        setLoading(false); 
      });  
    }

    function inputPeriodHandler(event) {
        setPeriod(event.target.value); 
        setPeriodId(event.target.value); 
    }
    const onSearchClicked = () => {
        // setPeriodId(period);
        listLocationMethod(); 
        listSingleUserMethod(); 
        setShowMapTable(false);  
    }

    const onUsersSearchClicked = () => {
      listMapUserMethod(); 
      setShowMapTable(true);  
    }

    useEffect(() => {
        listCountryMethod();  
        listStatesMethod(); 
    }, [countryDefault]); 



    // useEffect(() => {
    //     listLocationMethod();
    //     listSingleUserMethod();  
    // }, [periodId]); 

  // useEffect(() => {
  //   listMapUserMethod();
  // }, [countryDefault, stateDefault]); 


    // HEADER START
    const [tabActive, setTabActive] = useState(1); 
    const [showUserInfoPop, setShowUserInfoPop] = useState(false); 

    const [sidebarOpen, setSidebarOpen] = useState(false);
    const toggleSidebar = () => {
      setSidebarOpen(!sidebarOpen);
    };

    const countryOptions = [
        ...listCountry,
        // { value: 'India', label: 'India' },
    ];

    const stateOptions = [
        ...listStates,
        // { value: 'Goa', label: 'Goa' },
    ];

    // MAP
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: "AIzaSyBCiPF_dgKmOUIHZyKz2YY0WKwFI0Cn7C0",
    }); 

    const mapRef = React.useRef();
    const onMapLoad = React.useCallback((map) => {
        mapRef.current = map;
    }, []); 

    if(!isLoaded) return ".";
    if(!isLoaded) return "Loading...";
    // if(!isLoaded) {
    //     // return <CountdownPop />;
    // }

    if(loadError) return "Error Occured!";

    const handleMapMouseover = () => {
      setUserSelfie2(userSelfie);
      setImgHight(220);
      setImgWidth(220);
    };
    const handleMapMouseout = () => {
      setUserSelfie2(locIcon); 
      setImgHight(40);
      setImgWidth(60);
    };


  return (
    <section className='header-section'>
        <article className='header-top'>
            <div className="header-top-main">
                <div className="search-div">
                    <img src={menuIcon} alt="icon" onClick={toggleSidebar}/>
                    <div className="search-field">
                        <input 
                            type="search" 
                            placeholder='Search Member ID'
                            value={period}
                            onChange={inputPeriodHandler}
                        />

                        <img src={searchIcon} alt="icon"  onClick={() => {onSearchClicked()}} />
                    </div>
                </div>

                <div className="tab-div">
                    <button 
                    type='button' 
                    className={`map-btn ${tabActive === 1 && 'map-btn-active'}`}
                    onClick={()=> {setTabActive(1); navigate('/map')}}
                    ><i class="fa-solid fa-map-location-dot"></i>Map</button> 

                    <button 
                    type='button' 
                    className={`map-btn ${tabActive === 2 && 'map-btn-active'}`}
                    onClick={()=> {setShowMapTable(false); setTabActive(2); navigate('achievers')}}
                    ><i class="fa-solid fa-user-group"></i>Top Level Achiever</button> 

                    <button 
                    type='button' 
                    className={`map-btn ${tabActive === 3 && 'map-btn-active'}`} 
                    onClick={()=> {setShowMapTable(false); setTabActive(3); navigate('investors')}}
                    ><i class="fa-solid fa-arrow-up"></i>Top Investors</button> 
                </div>
            </div>
        </article>

        {/* SIDE NAV */}
        <article className={`header-sidebar ${sidebarOpen && 'sidebar-slide'}`}>
            <div className="sidebar-top">
                <h1>User Profile View</h1>
                <img src={menuIcon2} alt="icon" onClick={toggleSidebar}/>  
            </div>

            <div className="sidebar-search">
                <div className="search-field side-search">
                    <input 
                        type="search" 
                        placeholder='Search Member ID'
                        value={period}
                        onChange={inputPeriodHandler}
                    />

                    <img src={searchIcon} alt="icon" onClick={() => {onSearchClicked()}} />
                </div>
            </div>

            <div className="country-div">
                <Select
                options={countryOptions}
                defaultValue={countryDefault}
                placeholder="Select Country"
                onChange={setCountryDefault}
                styles={{
                    control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor: state.isFocused ? 'grey' : 'black',
                        borderRadius: '6px 6px 0px 0px',
                    }),
                    placeholder: (baseStyles, state) => ({
                        ...baseStyles,
                        color: 'black',
                    }),
                    option: (base) => ({
                        ...base,
                        border: '1px solid black',
                        height: '100%',
                    }),
                    dropdownIndicator: (baseStyles) => ({
                        ...baseStyles,
                        color: 'black',
                    }), 
                }}
                />
            </div>

            {countryDefault.value !== "" &&
            <div className="country-div state-div">
                <Select
                options={stateOptions}
                defaultValue={stateDefault}
                placeholder="Select State"
                onChange={setStateDefault}
                styles={{
                    control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor: state.isFocused ? 'grey' : 'black',
                        borderRadius: '6px 6px 0px 0px',
                    }),
                    placeholder: (baseStyles, state) => ({
                        ...baseStyles,
                        color: 'black',
                    }),
                    option: (base) => ({
                        ...base,
                        border: '1px solid black',
                        height: '100%',
                    }),
                    dropdownIndicator: (baseStyles) => ({
                        ...baseStyles,
                        color: 'black',
                    }), 
                }}
                />
                <button 
                  type='button' 
                  className='search-btn'
                  onClick={()=> {onUsersSearchClicked()}} 
                >Search Now</button>
            </div>
            }

            <div className="country-div">
              <Form action='logout' method='post'>
                <button className='search-btn logout-btn'>Logout</button> 
              </Form>
            </div>
        </article>

        {/* MAP */}
        <article className='map-article'>
            <GoogleMap
            mapContainerStyle={{
                height: "100vh",
            }}
            center={{lat: listLocation.latitude ? +listLocation.latitude : 22.5937, lng: listLocation.longitude ? +listLocation.longitude : 80.9629}}
            zoom={13}
            // center={{lat: 22.5937, lng: 80.9629}}
            // zoom={5}
            onLoad={onMapLoad}
            mapTypeId={"hybrid"}   // roadmap, hybrid, satellite, terrain
            >
            {/* <MarkerF 
            position={{lat:28.7041, lng:77.1025}}
            icon={locationIcon}
            onClick={(e) => {setShowUserInfoPop(true)}}
            />   
            */}

            {/* {myLocation.map(loc => (
            <Marker
                position={{lat: loc.lat, lng: loc.lng}}
                icon={loc.icon}
                onClick={(e) => {setShowUserInfoPop(true)}}
                // onMouseOver={(e) => {setShowUserInfoPop(true)}}
            />
            ))} */}


            <Marker
                position={{lat: +listLocation.latitude, lng: +listLocation.longitude}}
                icon={{
                  url: userSelfie2, 
                  scaledSize: new window.google.maps.Size(imgHight, imgWidth),
                }}
                onClick={(e) => {setShowUserInfoPop(true)}}
                onMouseOver={handleMapMouseover}
                onMouseOut={handleMapMouseout}   
            />

            </GoogleMap>
        </article>
        
        {/* MAIN SECTION */}
        <main className='main-div'>
          {props.passedComponent} 
        </main>

        {showUserInfoPop && <UserInfoPop 
        setShowUserInfoPop = {setShowUserInfoPop} 
        singleUser = {singleUser}
        />
        }

        {showCountdownPop && <CountdownPop 
        />}

        {showMapTable && tabActive === 1 && <MapTable  
          listMapUser = {listMapUser}
          loading = {loading}  
        />}

    </section>
  )
}

export default Header;


// const myLocation = [
//     {
//         lat:28.7041, 
//         lng:77.1025,
//         icon: locationIcon
//     },
//     {
//         lat:28.7150, 
//         lng:77.1230,
//         icon: locationIcon2
//     }
// ];