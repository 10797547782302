import axios from 'axios';
import { getAuthToken } from '../utils/Auth';

const accessToken = getAuthToken(); 

const API = axios.create({
    baseURL: "https://vtcncoin.com",
    timeout: 120000,
    headers: {
        "Content-Type": "application/json", 
        "Accept": "application/json", 
        "Authorization": accessToken 
    }
});

export default API; 