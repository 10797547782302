import bcIcon from "./bcicon.png"; 
import menuIcon from "./menuIcon.png";
import menuIcon2 from "./menuIcon2.png";
import searchIcon from "./searchIcon.png"; 
import locationIcon from "./locationIcon.png";
import searchBtn from "./searchBtn.png";
import userPic from "./userPic.png";
import topBg from "./topBg.png";
import bottomBg from "./bottomBg.png";
import userLocation from "./userLocation.png"; 
import coinIcon from "./coinIcon.png";
import bcPop from "./bcPop.png";
import crossBox1 from "./crossBox1.png";
import crossBox2 from "./crossBox2.png";
import locationIcon2 from "./locationIcon2.png";
import mapBg from "./map-bg.png";
import locIcon from "./locIcon.png"; 
import coinIcon2 from "./coinIcon2.png";
import coinIcon3 from "./coinIcon3.png";

export { 
    bcIcon, menuIcon, menuIcon2, searchIcon, locationIcon, searchBtn, userPic, topBg, bottomBg, userLocation, coinIcon,
    bcPop, crossBox1, crossBox2, locationIcon2, mapBg, locIcon, coinIcon2, coinIcon3, 
};
