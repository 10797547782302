import React, { useState } from 'react';
import './Login.css'; 
import { bcIcon } from '../../assets';
import { useNavigate, useSubmit } from 'react-router-dom';
import axios from "axios";
import { toast } from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css';

const Login = () => {
    // const submit = useSubmit();
    const navigate = useNavigate(); 
    const [isSentOtp, setIsSentOtp] = useState(false); 
    const [email, setEmail] = useState(null);
    const [otp, setOtp] = useState(null);

    const API = axios.create({
        baseURL: "https://vtcncoin.com",
        timeout: 120000,
        headers: {
          "Content-Type": "application/json;charset=utf-8", 
          "Accept": "application/json",
          "Access-Control-Allow-Origin": '*', 
        }
    });

    function handleEmail(event) {
        setEmail(event.target.value); 
    }
    function handleOtp(event) {
        setOtp(event.target.value);  
    }

    function getOtpMethod() {    
        API.get(`/map/auth/otp/login?email=${email}`)
        .then((res) => {
            toast.success(res.data.message, {position:"top-center"});
            setIsSentOtp(true);  
        }).catch((err) => {
            toast.error(err.response.data.message, {position:"top-center"});
        });  
    }

    function loginFormSubmit() {
        const body = {
          email: email,
          otp: otp
        };

        API.post("/map/auth/login", body)
        .then((res) => {
          toast.success(res.data.message, {position:"top-center"});
          setIsSentOtp(false); 
    
          // ACCESS TOKEN
          const accessToken = res.data.data;
          localStorage.setItem('accessToken', accessToken); 
          localStorage.setItem('loggedIn', true); 
    
          // TOKEN EXPIRATION
          const expiration = new Date();
          expiration.setHours(expiration.getHours() + 5); 
          localStorage.setItem('expiration', expiration.toISOString()); 
    
          // REDIRECT
          localStorage.setItem('reloadCount', 1); 
          return navigate("/map"); 
    
        }).catch((err) => {
          toast.error(err.response.data.message, {position:"top-center"});  
        }); 
    }
    

  return (
    <section className='login-sec'>
        <div className="form-div">
            <img src={bcIcon} alt="logo" className='lg-logo'/>
            <form className='login-form'>
                {!isSentOtp &&
                <>
                <i class="fa-regular fa-envelope"></i>
                <input 
                    type="email" 
                    placeholder='Email ID' 
                    name="email"
                    onChange={handleEmail}
                    readOnly={isSentOtp ? true : false}
                    required
                /> 
                <p>OTP will be sent to your Registered Email Address.</p>
                </>
                }

                {isSentOtp &&
                <>
                <i class="fa-solid fa-mobile-screen-button"></i>
                <input 
                    type="tel" 
                    placeholder='Enter OTP' 
                    name="otp"
                    onChange={handleOtp} 
                    required
                /> 
                <p className='otp-text'>OTP will be sent to your Registered Email Address.</p>
                </>
                }

                <button 
                    type='button' 
                    className='login-btn'
                    onClick={()=> {isSentOtp ? loginFormSubmit() : getOtpMethod()}} 
                >{isSentOtp ? 'Login' : 'Verify'}</button>
            </form>
        </div>
    </section>
  )
}

export default Login; 