// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/map-bg.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-sec {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
}

.form-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.login-form {
    padding-top: 50px;
}

.login-form input {
    border: 1px solid #000000;
    border-radius: 5px;
    outline: none;
    width: 380px;
    padding: 12px 35px;
    color: #000000;
    font-size: 18px;
}

.login-form input::placeholder {
    color: #000000;
    font-size: 18px;
}

.login-form i {
    position: relative;
    left: 1.9rem;
    top: 0.1rem;
    font-size: 24px;
}

.login-form p {
    color: #000000;
    font-size: 17px;
    margin-top: 14px;
    margin-left: 25px;
}

.login-btn {
    background-color: #1C3F7B;
    border-radius: 5px; 
    color: #ffffff;
    font-size: 20px;
    font-weight: 500;
    width: 380px;
    border: none;
    padding: 12px 0;
    margin-top: 30px;
    margin-left: 19px;
}

.otp-text {
    opacity: 0;
}`, "",{"version":3,"sources":["webpack://./src/pages/Login/Login.css"],"names":[],"mappings":"AAAA;IACI,yDAAgD;IAChD,aAAa;IACb,sBAAsB;IACtB,4BAA4B;AAChC;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;AACjB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,aAAa;IACb,YAAY;IACZ,kBAAkB;IAClB,cAAc;IACd,eAAe;AACnB;;AAEA;IACI,cAAc;IACd,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,WAAW;IACX,eAAe;AACnB;;AAEA;IACI,cAAc;IACd,eAAe;IACf,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,cAAc;IACd,eAAe;IACf,gBAAgB;IAChB,YAAY;IACZ,YAAY;IACZ,eAAe;IACf,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,UAAU;AACd","sourcesContent":[".login-sec {\r\n    background-image: url('../../assets/map-bg.png');\r\n    height: 100vh;\r\n    background-size: cover;\r\n    background-repeat: no-repeat;\r\n}\r\n\r\n.form-div {\r\n    display: flex;\r\n    flex-direction: column;\r\n    justify-content: center;\r\n    align-items: center;\r\n    height: 100vh;\r\n}\r\n\r\n.login-form {\r\n    padding-top: 50px;\r\n}\r\n\r\n.login-form input {\r\n    border: 1px solid #000000;\r\n    border-radius: 5px;\r\n    outline: none;\r\n    width: 380px;\r\n    padding: 12px 35px;\r\n    color: #000000;\r\n    font-size: 18px;\r\n}\r\n\r\n.login-form input::placeholder {\r\n    color: #000000;\r\n    font-size: 18px;\r\n}\r\n\r\n.login-form i {\r\n    position: relative;\r\n    left: 1.9rem;\r\n    top: 0.1rem;\r\n    font-size: 24px;\r\n}\r\n\r\n.login-form p {\r\n    color: #000000;\r\n    font-size: 17px;\r\n    margin-top: 14px;\r\n    margin-left: 25px;\r\n}\r\n\r\n.login-btn {\r\n    background-color: #1C3F7B;\r\n    border-radius: 5px; \r\n    color: #ffffff;\r\n    font-size: 20px;\r\n    font-weight: 500;\r\n    width: 380px;\r\n    border: none;\r\n    padding: 12px 0;\r\n    margin-top: 30px;\r\n    margin-left: 19px;\r\n}\r\n\r\n.otp-text {\r\n    opacity: 0;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
