import React, { useState } from 'react'; 
import './MapTable.css'; 
import { userPic } from '../assets/index';
import LoadingSpinner from '../components/LoadingSpinner';


const MapTable = ({loading, listMapUser}) => {

  return (
    <section className='achiever-section'>
        <article className='table-article'>
            <div className="table-wrapper">
            <table className='fl-table'>
              <thead>
              <tr>
                  <th>Sl. No.</th>
                  <th>Level Rank</th>
                  <th>Image</th>
                  <th>Country</th>
                  <th>State</th>
                  <th>Member ID</th>
                  <th>Member Name</th>
                  <th className='last-th'>Total Earnings</th>
              </tr>
              </thead>

              <tbody>
              {loading && <LoadingSpinner />}
              {listMapUser.map((data, index) => { 
              return(
              <tr key={index}>
                  <td>{index+1}</td>
                  <td>{data.level}</td>
                  <td><img src={data.kyc.userSelfie} alt="user pic" className='td-img'/></td>
                  <td>{data.country}</td>
                  <td>{data.kyc.state ? data.kyc.state : '--'}</td>
                  <td>{data.referralCode}</td>
                  <td>{data.name}</td>
                  <td>{parseFloat(data.totalIncome).toFixed(2)}</td>
              </tr>
              )})}

              {/* <tr>
                  <td>2</td>
                  <td>1</td>
                  <td><img src={userPic} alt="icon" className='td-img'/></td>
                  <td>India</td>
                  <td>Maharashtra</td>
                  <td>VT-123456</td>
                  <td>Sam</td>
                  <td>56465.00</td>
              </tr> */}

              </tbody> 
              </table>
            </div>
        </article>
    </section>
  )
}

export default MapTable; 
