// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../assets/wheel2.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CountdownPop {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0%;
    left: 0%; 
    z-index: 1000;
    background-color: rgba(255, 255, 255, 0.3);
    -webkit-backdrop-filter: blur(2px);
            backdrop-filter: blur(2px); 
}

.wheel {
    position: relative;
    bottom: 3rem;
    overflow: hidden;
    background: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    /* height: 500px; */
    height: 490px;
    width: calc(18509px / 30); 
    /* transform: perspective(500px) rotateX(-25deg) translateY(-150px);
    -webkit-box-reflect: below 1px linear-gradient(transparent 90%, #0004);
    animation: animateWheel 1s steps(30) infinite; */
    transform: rotate(90deg);
    animation: animateWheel 6s steps(30) infinite; 
}

@keyframes animateWheel {
    from {
        background-position: 0;
    }
    to {
        /* background-position: 18509px; */
        background-position: 18645px;
    }
}

`, "",{"version":3,"sources":["webpack://./src/components/CountdownPop.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,WAAW;IACX,aAAa;IACb,eAAe;IACf,OAAO;IACP,QAAQ;IACR,aAAa;IACb,0CAA0C;IAC1C,kCAA0B;YAA1B,0BAA0B;AAC9B;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,gBAAgB;IAChB,mDAAuC;IACvC,mBAAmB;IACnB,aAAa;IACb,yBAAyB;IACzB;;oDAEgD;IAChD,wBAAwB;IACxB,6CAA6C;AACjD;;AAEA;IACI;QACI,sBAAsB;IAC1B;IACA;QACI,kCAAkC;QAClC,4BAA4B;IAChC;AACJ","sourcesContent":[".CountdownPop {\r\n    display: flex;\r\n    flex-direction: column;\r\n    justify-content: center;\r\n    align-items: center;\r\n    width: 100%;\r\n    height: 100vh;\r\n    position: fixed;\r\n    top: 0%;\r\n    left: 0%; \r\n    z-index: 1000;\r\n    background-color: rgba(255, 255, 255, 0.3);\r\n    backdrop-filter: blur(2px); \r\n}\r\n\r\n.wheel {\r\n    position: relative;\r\n    bottom: 3rem;\r\n    overflow: hidden;\r\n    background: url(\"../assets/wheel2.png\");\r\n    /* height: 500px; */\r\n    height: 490px;\r\n    width: calc(18509px / 30); \r\n    /* transform: perspective(500px) rotateX(-25deg) translateY(-150px);\r\n    -webkit-box-reflect: below 1px linear-gradient(transparent 90%, #0004);\r\n    animation: animateWheel 1s steps(30) infinite; */\r\n    transform: rotate(90deg);\r\n    animation: animateWheel 6s steps(30) infinite; \r\n}\r\n\r\n@keyframes animateWheel {\r\n    from {\r\n        background-position: 0;\r\n    }\r\n    to {\r\n        /* background-position: 18509px; */\r\n        background-position: 18645px;\r\n    }\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
